import Component from "./Component";
import ComponentContainer from "./ComponentContainer";
import mitt from 'mitt'

export default class VenveoApplication {
    constructor() {
        this.$registry = {};
        this.$observedHandles = [];
        this.$observer = null;
        this.$emitter = mitt();
    }

    /**
     *
     * @param handle
     * @param selector
     * @param component
     * @param options
     */
    registerComponent(handle, selector, component, options = {}) {
        let deferred = false;
        let lazy = false;
        const $element = document.querySelector(selector);

        if (!component instanceof Component) {
            console.error('Attempted to register an invalid component', component)
            return false
        }

        if (component instanceof Promise) {
            deferred = true
        }
        if ($element && $element.classList.contains('lazy-component')) {
            lazy = true
        }

        const container = new ComponentContainer(handle, $element, component, deferred, lazy, options, this)

        this.$registry[handle] = container
        return container
    }

    _addToObserver(componentRegistration) {
        console.log('Added to observer')
        this.$observedHandles.push(componentRegistration.handle)
    }

    _initObserver() {
        let components = []
        this.$observedHandles.forEach(handle => components.push(this.$registry[handle]))

        const elements = document.querySelectorAll('.lazy-component')
        this.$observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    const target = entry.target
                    components.forEach((c) => {
                        if (c.$element === target) {
                            if (!c.instance) {
                                if (c.deferred === false) {
                                    c.init();
                                    console.log('Lazy: Initialized deferred component', c)
                                } else {
                                    c.asyncSetup().then((e) => {
                                        c.init();
                                        console.log('Lazy: Loaded & initialized deferred component', c)
                                    });
                                }
                            }
                        }
                    })
                }
            })
        })
        elements.forEach(element => this.$observer.observe(element))
    }

    async init() {
        // Iterate over all component in the registry
        for (const handle in this.$registry) {
            if (!this.$registry.hasOwnProperty(handle)) {
                continue
            }

            /** @type {ComponentContainer} */
            const componentRegistration = this.$registry[handle]
            // Element not present on page
            if (!componentRegistration.$element) {
                console.warn('Element not present on page')
                continue
            }


            if (componentRegistration.deferred) {
                if (componentRegistration.lazy) {
                    this._addToObserver(componentRegistration)
                    console.log('Added lazy component to observer')
                } else {
                    componentRegistration.asyncSetup().then(() => {
                        componentRegistration.init()
                        console.debug('Initialized deferred component', componentRegistration)
                    });
                }
                continue;
            }

            componentRegistration.setup()
            componentRegistration.init()
        }

        this._initObserver();
    }
}