import '../img/map1.png'
import '../img/amp-logo-2022.svg'

// Fonts
// Gilroy
import '../fonts/gilroy-light-webfont.woff'
import '../fonts/gilroy-light-webfont.woff2'
import '../fonts/gilroy-light-webfont.eot'

import '../fonts/gilroy-regular-webfont.woff'
import '../fonts/gilroy-regular-webfont.woff2'
import '../fonts/gilroy-regular-webfont.eot'

import '../fonts/gilroy-medium-webfont.woff'
import '../fonts/gilroy-medium-webfont.woff2'
import '../fonts/gilroy-medium-webfont.eot'

import '../fonts/gilroy-semibold-webfont.woff'
import '../fonts/gilroy-semibold-webfont.woff2'
import '../fonts/gilroy-semibold-webfont.eot'

import '../fonts/gilroy-bold-webfont.woff'
import '../fonts/gilroy-bold-webfont.woff2'
import '../fonts/gilroy-bold-webfont.eot'
