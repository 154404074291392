
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// polyfills
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'picturefill';

import '../css/app.css'

// Import static assets
import './static.assets';

// Set background images lazily
document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

import Headroom from './components/ui/Headroom'
import HeaderSearch from './components/ui/HeaderSearch'
import MobileNav from './components/ui/MobileNav'
import SmoothAnchor from './components/ui/SmoothAnchor'
import VenveoApplication from "./base/VenveoApplication";

const application = new VenveoApplication();
application.registerComponent('header', 'header', Headroom)
application.registerComponent('header-search', '[data-header-search]', HeaderSearch)
application.registerComponent('mobile-nav', '[data-mobile-nav]', MobileNav)
application.registerComponent('glightbox', '.glightbox', import(/* webpackChunkName: "glightbox" */ './components/ui/Glightbox'))
application.registerComponent('accordion', '[data-accordion]', import(/* webpackChunkName: "accordion" */ './components/ui/Accordion'))
application.registerComponent('smooth-scroll', '[data-smooth-scroll]', SmoothAnchor)
application.registerComponent('plyr-hero', '#plyrhero-video', import(/* webpackChunkName: "plyr-hero" */ './components/ui/PlyrHero'))
application.registerComponent('read-more', '[data-readmore]', import(/* webpackChunkName: "read-more" */ './components/ui/ReadMoreProse'))
application.registerComponent('testimonial-slider', '#testimonialSlider', import(/* webpackChunkName: "testimonial-slider" */ './components/ui/sliders/TestimonialSlider'))
application.registerComponent('resource-finder', '#resource-finder', import(/* webpackChunkName: "resource-finder" */ './components/pages/ResourceFinder'))
application.registerComponent('dropdown-filters', '[data-dropdown-filter]', import(/* webpackChunkName: "dropdown-filters" */ './components/ui/DropdownFilters'))
// application.registerComponent('homepage', '.section-homepage', import(/* webpackChunkName: "pages-homepage" */ './components/pages/Homepage'))


application.init().then(() => {
    console.log('Site is ready.')
})

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
