export default class ComponentContainer {
    constructor(handle, $element, component, deferred, lazy, options, $application) {
        this.dependencies = []; // TODO

        this.handle = handle;
        this.$element = $element;

        this.component = component;
        this.deferred = deferred;
        this.lazy = lazy;
        this.options = options;

        this.instance = null;

        /** @type {VenveoApplication} */
        this.$application = $application;
    }

    async asyncSetup() {
        if (this.component instanceof Promise) {
            const {default: Component} = await this.component;
            this.component = Component;
            this.setup();
        } else {
            console.error('Tried to call async setup on non-deferred component')
        }
    }

    setup() {
        this.instance = new this.component(this.options)
        console.log('[APP] SETUP', this);
    }

    init() {
        if (this.instance) {
            this.instance.init();
            console.log('[APP] INIT', this);
        } else {
            console.error('Init called before setup')
        }
    }
}