'use strict';

import Component from "../../base/Component";

export default class MobileNav extends Component {
    constructor(props) {
        super(props);
        this.mobileNav = null;
        this.mobileNavTriggers = null;
        this.activeMobileNavClass = null;
        this.mobileNavCloseTriggers = null;
        this.mobileNavOverlay = null;
        this.activeMobileNavClass = 'mobile-nav-active';
        this.mobileNav = document.querySelector('[data-mobile-nav]');
    }

    init() {

        const triggerCallback = (event) => {
            if( !document.body.classList.contains(this.activeMobileNavClass) ) {
                return expandMobileNav();
            } else if ( document.body.classList.contains(this.activeMobileNavClass) ) {
                return hideMobileNav();
            }
        }
        
        const expandMobileNav = () => {
            document.body.classList.add(this.activeMobileNavClass);
            this.mobileNav.classList.remove('invisible');
        }
        
        const getDuration = () => {
            let duration
            this.mobileNav.classList.forEach((className) => {
                if( className.search('duration') >= 0 )
                    duration = className
            })
            return duration.replace('duration-', '')
        }

        const hideMobileNav = () => {
            document.body.classList.remove(this.activeMobileNavClass);
            setTimeout(() => this.mobileNav.classList.add('invisible'), getDuration());
        }

        // Mobile Nav triggers
        this.mobileNavTriggers = document.querySelectorAll('[data-hamburger]');
        // Mobile Nav close element
        this.mobileNavCloseTriggers = document.querySelectorAll('[data-mobile-nav-close]');
        // Mobile Nav overlay close
        this.mobileNavOverlay = this.mobileNav.querySelector('[data-mobile-nav-outside]');

        if( this.mobileNavOverlay !== null ) {
            this.mobileNavOverlay.addEventListener('click', hideMobileNav);
        } else {
            console.log('No [data-mobile-nav-outside] element exist.')
        }

        if( this.mobileNavCloseTriggers.length > 0 ) {
            this.mobileNavCloseTriggers.forEach((trigger) => {
                trigger.addEventListener('click', hideMobileNav);
            });
        } else {
            console.log('No [data-mobile-nav-close] element exist.')
        }

        if( this.mobileNavTriggers.length > 0 ) {
            this.mobileNavTriggers.forEach((trigger) => {
                trigger.addEventListener('click', triggerCallback);
            });
        } else {
            console.log('No [data-mobile-nav-trigger] element exist.')
        }
    }
}